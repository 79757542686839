<template>
  <b-modal id="change-status" hide-footer title="تغییر وضعیت">
    <form @submit.prevent="updatedOrderStatus()">
      <b-form-group>
        <multiselect
          placeholder="وضعیت"
          v-model="orderStatus"
          open-direction="bottom"
          :options="statusOptions"
          label="title"
          track-by="value"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group label="">
        <label for="no_charge_checkbox">
          <span class="ml-2">عدم افزایش شارژ کیف پول کاربر</span>
          <input id="no_charge_checkbox" type="checkbox" v-model="noCharge" />
        </label>
      </b-form-group>
      <b-form-group v-if="$route.query.developer_mode" label="">
        <label for="call_digipay">
          <span class="ml-2">اطلاع رسانی به دیجی پی</span>
          <input id="call_digipay" type="checkbox" v-model="call_digipay" />
        </label>
      </b-form-group>

      <div class="text-center mt-3">
        <button class="btn btn-primary" :disabled="disabled">ویرایش</button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import OrderChangeStatusImpl from "@@/core/components/order/OrderChangeStatus/OrderChangeStatusImpl";

export default {
  extends: OrderChangeStatusImpl,
};
</script>

<style scoped></style>
