<template>
    <div v-if="orders" id="print">
      <section class="print-orders">
        <b-table
              responsive
              hover
              bordered
              :items="orders"
              :fields="fields"
          >
            <template #cell(customer)="row">
                {{address(row.item).first_name +" "+ address(row.item).last_name }}
            </template>
            <template #cell(city)="row">
                {{address(row.item).city ? address(row.item).city.name : "" }}
            </template>
            <template #cell(payment)="row">
                <span
                  v-if="row.item.invoices.length&&row.item.invoices[0].type == 'gateway'"
                >
                  درگاه {{ row.item.invoices[0].payments.length ? row.item.invoices[0].payments[0].gateway_label : "" }}
                </span>
                <span
                  v-else-if="row.item.invoices.length&&row.item.invoices[0].type == 'wallet'"
                >
                  کیف پول
                </span>
                
            </template>
            <template  #cell(input1)="row">
              <VueBarcode
                  fontSize="12"
                  width="1"
                  marginBottom="0"
                  height="20"
                  :value="row.item.id"
                  :text="row.item.id"
                  :display-value="false"
              >
                {{row.item.id}}
              </VueBarcode>
            </template>
            <template  #cell(input2)="row">
                  <input  class="inputCheckBox"  :value="row" type="checkbox">
            </template>
          </b-table>
          <div class="text-center print-order-btn mb-4">
      <button class="btn btn-primary text-white" @click="orderPrint()">
        چاپ فاکتور
      </button>
      <!-- <button class="btn btn-print text-white mr-1">بازگشت</button> -->
    </div>
     
      </section>
    </div>
  </template>
  <script>
  import OrderPrintImpl from '@@/core/pages/order/printOrdersList/OrderPrintImpl'
  export default {
    extends: OrderPrintImpl
  }
  </script>
  <style scoped>
.inputCheckBox{
    width: 24px;
    height: 24px;
}
</style>
  