import { BModal, BFormGroup, BFormInput } from "bootstrap-vue";
export default {
  name: "OrderChangeStatus",
  components: {
    BModal,
    BFormGroup,
    BFormInput,
  },
  props: {
    disabled: Boolean,
    order: Object,
  },
  data() {
    return {
      tracking_code: "",
      amount: "",
    };
  },
  methods: {
    refundOrderEmiter() {
      this.$root.$emit("bv::show::modal", "refund-order");
    },
    async refundOrder() {
      try {
        this.$parent.disabled = true;
        const response = await this.$axios.post(`admin/manual-digipay-refund`, {
          tracking_code: this.tracking_code,
          amount: this.amount,
        });
        this.$root.$emit("bv::hide::modal", "refund-order");
        this.$root.notify(response.data.message, "success");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.$parent.disabled = false;
      }
    },
  },
};
