<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">تغییر وضعیت سفارشات</h5>
      </div>
      <div class="card-body">
        <div class="card h-100">
          <div class="card-body position-relative">
            <b-form @submit="keyEventHandler">
              <b-form-group label="بارکد را وارد کنید">
                <b-form-input
                  ref="orderId"
                  id="orderId"
                  @change="keyEventHandler"
                  v-model="orderId"
                  :disabled="disabled"
                  autofocus
                ></b-form-input>
              </b-form-group>
              <b-form-group label="وضعیت سفارش">
                <multiselect
                  placeholder="وضعیت"
                  v-model="orderStatus"
                  open-direction="bottom"
                  :options="statusOptions"
                  label="title"
                  track-by="value"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
              <b-button
                type="button"
                @click="changeStatus()"
                :disabled="disabled"
                variant="primary"
                >ثبت</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
      <div class="card mt-3" v-if="lastOrderDeliverd.length">
        <div class="card-header">
          لیست سفارشات تایید شده امروز
        </div>
        <div class="card-body">
          <b-table
            responsive
            hover
            bordered
            :items="lastOrderDeliverd"
            :fields="fields"
          >
            <template #cell(created_at)="row">
              {{ row.item.created_at | persianDate }}
            </template>
            <template #cell(updaterable)="row">
              {{ row.item.updaterable ? row.item.updaterable.name : "" }}
            </template>

            <template #cell(status)="row">
              <order-status :status="row.item.status"></order-status>
            </template>
            <template #cell(receiver)="row">
              {{ getReceiverName(row.item) }}
            </template>

            <template #cell(delete)="row">
              <b-button
                :disabled="disabled"
                @click="deleteRow(row.item.id)"
                variant="danger"
              >
                حذف
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import OrderStatus from "@@/core/components/order/OrderStatus/OrderStatus";

import {
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BListGroup,
  BListGroupItem,
  BTable,
} from "bootstrap-vue";
import { getStatusLabel } from "@@/core/libraries/adminFunctions";

export default {
  name: "ShopCreate",
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BListGroup,
    BListGroupItem,
    BTable,
    OrderStatus,
  },
  data() {
    return {
      lastOrderDeliverd: [],
      fields: [
        {
          key: "id",
          label: "شناسه",
        },

        {
          key: "receiver",
          label: "گیرنده",
          sortable: true,
        },
        {
          key: "items_count",
          label: "تعداد آیتم",
          sortable: true,
        },

        {
          key: "status",
          label: "وضعیت",
          sortable: true,
        },
        {
          key: "updaterable",
          label: "ادمین",
          sortable: true,
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
          sortable: true,
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      orderId: "",
      disabled: false,
      orderStatus: {
        value: "delivered",
        title: getStatusLabel("delivered"),
      },
      statusOptions: [
        {
          value: "delivered",
          title: getStatusLabel("delivered"),
        },
        {
          value: "in_progress",
          title: getStatusLabel("in_progress"),
        },

        {
          value: "new",
          title: getStatusLabel("new"),
        },
        {
          value: "wait_for_payment",
          title: getStatusLabel("wait_for_payment"),
        },
        {
          value: "canceled",
          title: getStatusLabel("canceled"),
        },
        {
          value: "failed",
          title: getStatusLabel("failed"),
        },
        {
          value: "in_examination",
          title: getStatusLabel("in_examination"),
        },
      ],
    };
  },
  methods: {
    getReceiverName(order) {
      let address;
      try {
        address = JSON.parse(order.address);
      } catch (e) {
        return "نامشخص";
      }
      return address.first_name + " " + address.last_name;
    },
    deleteRow(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات سفارش را به وضعیت درحال تکمیل برمیگرداند",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          this.orderId = id;
          this.changeStatus("new");
        });
    },
    getLastOrderDeliverd() {
      this.$axios.get("admin/delivered_orders").then((res) => {
        this.lastOrderDeliverd = res.data.data.orders;
      });
    },
    keyEventHandler() {
      this.changeStatus();
    },

    async changeStatus(requiredStatus = null) {
      try {
        let fd = new FormData();
        fd.append(
          "status",
          requiredStatus
            ? requiredStatus
            : this.orderStatus
            ? this.orderStatus.value
            : ""
        );
        fd.append("ids[0]", this.orderId);
        this.disabled = true;
        const response = await this.$axios.post(
          `admin/orders/status/changes`,
          fd
        );
        this.$root.notify(response.data.message, "success");
        this.getLastOrderDeliverd();
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
        this.orderId = null;
        setTimeout(() => {
          this.$refs.orderId.focus();
        }, 100);
      }
    },
  },

  mounted() {
    this.getLastOrderDeliverd();
  },
};
</script>

<style scoped>
ul.items li:hover {
  background: red;
}
ul.items li {
  background: #80808061;
  padding: 8px 16px;
  transition: all 0.3s;
  cursor: pointer;
}
ul.items {
  display: flex;
  gap: 8px;
  margin: 16px;
}
</style>
