<template>
  <b-modal id="refund-order" hide-footer title="مرجوعی دستی">
    <form @submit.prevent="refundOrder()">
      <b-form-group label="مبلغ (تومان)">
        <b-form-input
          :value="amount | priceinput"
          @input="
            (value) => {
              amount = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
        ></b-form-input>
      </b-form-group>
      <b-form-group label="کد رهگیری">
        <b-form-input type="text" v-model="tracking_code"> </b-form-input>
      </b-form-group>
      <div class="text-center mt-3">
        <button class="btn btn-primary" :disabled="disabled">ثبت</button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import refundOrderImpl from "@@/core/components/order/refundOrder/refundOrderImpl";

export default {
  extends: refundOrderImpl,
};
</script>

<style scoped></style>
