<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">گزارش دیجی پی</h5>
        <div style="flex: 1"></div>
        <ExcelDownload
          :extraQuery="extraQuery"
          :url="
            `/admin/reports/digipay?start_date=${filter.date.startDate}&end_date=${filter.date.endDate}`
          "
        />
      </div>
      <div class="row px-4 not-in-print">
        <form
          autocomplete="new-password"
          @submit.prevent="doFilter"
          class="col-12 row"
        >
          <div class="col-6">
            <DatePickerRange v-model="filter.date" />
          </div>

          <div class="col-12 d-flex justify-content-end">
            <button :disabled="disabled" type="submit" class="btn btn-primary">
              فیلتر
            </button>
          </div>
        </form>
      </div>
      <b-list-group>
        <b-list-group-item>
          <span>
            تعداد اقلام فروش رفته :
          </span>
          <span>{{ totals.items_count | price }} </span>
        </b-list-group-item>

        <b-list-group-item>
          <span>
            جمع اقلام فروش رفته :
          </span>
          <span>{{ totals.amount | price }} </span>
        </b-list-group-item>
      </b-list-group>

      <div id="print" class="p-4">
        <b-table
          id="main-table"
          v-if="reports"
          bordered
          striped
          :fields="fields"
          :items="reports.data"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          responsive="sm"
        >
          <template v-slot:cell(title)="data">
            {{ data.item.first_name + " " + data.item.last_name }}
          </template>

          <template v-slot:cell(sumInvoice)="data">
            {{ (data.item.total_amount_without_discount) | price }}
          </template>
          <template v-slot:cell(customer_id)="data">
            {{ data.item.created_at | persianDate }}
          </template>
        </b-table>
        <div v-else-if="reports == ''" class="alert alert-danger">
          موردی یافت تشد
        </div>
        <div v-else class="alert alert-primary">در حال بارگزاری</div>
        <pagination
          :router="true"
          v-if="reports && reports.data != ''"
          :disabled="disabled"
          :limit="2"
          :data="reports"
          @pagination-change-page="changePage"
        />
      </div>
    </div>
  </main>
</template>

<script>
import {
  BTable,
  BFormGroup,
  BFormInput,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";
import configProvider from "@@/core/configProvider";
import reportMixin from "@@/core/mixins/reportMixin";
import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload.vue";
import digipayTransactionsImpl from "../../digipayTransactions/digipayTransactionsImpl";

function Filter() {
  this.reportType = "";
  this.search = "";
  this.category_id = "";
  this.date = {
    startDate: "",
    endDate: "",
  };
}

Filter.prototype.set = function(filters) {
  Object.entries(filters).forEach(([index, value]) => {
    this[index] = value;
  });
};

export default {
  name: "ProductsReport",
  components: {
    DatePickerRange,
    BTable,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    ExcelDownload,
    digipayTransactionsImpl,
  },
  configProvider,
  data() {
    return {
      disabled: false,
      search: "",
      totals: {
        amount: null,
        items_count: null,
      },
      fields: [
        {
          key: "id",
          label: "شناسه ",
        },
        {
          key: "title",
          label: "نام مشتری",
        },
        {
          key: "city",
          label: "شهر",
        },
        {
          key: "items_count",
          label: "تعداد آیتم ها",
        },

        {
          key: "sumInvoice",
          label: "مبلغ فاکتور",
        },
        {
          key: "created_at",
          label: "تاریخ",
        },
      ],
      page: 1,
      reports: null,
      list: [],
      sortBy: null,
      sortDesc: null,
      filter: new Filter(),
      appliedFilter: new Filter(),
      showVarietiesProductId: null,
      sumTotalSale: null,
      sumSellQuantity: null,
      widgetData: {},
    };
  },
  mixins: [reportMixin],
  watch: {
    "$route.query": {
      handler(nV) {
        if (nV.page) {
          this.changePage(nV.page);
        }
      },
    },
  },
  methods: {
    findStatus(status) {
      switch (status) {
        case "new":
          return "در انتظار تکمیل";
        case "in_progress":
          return "در حال پردازش ";
        case "in_examination":
          return "در حال بررسی ";
        case "wait_for_payment":
          return "در انتظار پرداخت";
        case "delivered":
          return "ارسال شده";
        case "canceled":
          return "کنسل شده";
        case "failed":
          return "خطا";
        case "reserved":
          return "رزرو شده";
      }
    },
    removeFilter() {
      this.filter = new Filter();
      this.loadData();
    },

    doFilter() {
      this.showVarietiesProductId = null;
      this.loadData();
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },

    async loadData() {
      try {
        this.appliedFilter.set(this.filter);
        this.disabled = true;
        const response = await this.$axios.get("admin/reports/digipay", {
          params: {
            start_date: this.appliedFilter.date.startDate,
            end_date: this.appliedFilter.date.endDate,
            page: this.page,
            widgets: "",
          },
        });
        this.reports = response.data.data.orders;
        this.totals = response.data.data.totals;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>

<style>
.print-only {
  display: none;
}
@media print {
  .not-in-print {
    display: none !important;
  }

  .app-content .side-app {
    padding: 0 !important;
  }

  .print-only {
    display: block !important;
  }

  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    padding: 0 !important;
  }
  #print {
    width: 80%;
  }

  #main-table th:last-child,
  #main-table td:last-child {
    display: none;
  }

  #print .not-in-print {
    display: none;
  }

  .mr-2-print {
    margin-right: 20px;
  }
}
</style>
